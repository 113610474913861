const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || ''
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || ''
const CONSENT_URL = process.env.REACT_APP_CONSENT_URL || ''
const IDENTITY_SERVICE_URL = process.env.REACT_APP_IDENTITY_SERVICE_URL || ''
const OVERRIDE_DOMAIN = process.env.REACT_APP_OVERRIDE_DOMAIN || ''
const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_URL || ''

const links = {
  about: `${CONSENT_URL}/#/about`,
  support: `${CONSENT_URL}/#/support`,
  impressum: `${CONSENT_URL}/#/impressum`,
  blockedIp: `${CONSENT_URL}/#/blocked-ip`,
  agb: `${CONSENT_URL}/#/agb`,
  dsb: `${CONSENT_URL}/#/privacy-policy`,
}

export default {
  url: REACT_APP_PUBLIC_URL,
  backendUrl: BACKEND_URL,
  landingPageURL: LANDING_PAGE_URL,
  links,
  consentUrl: CONSENT_URL,
  identityServiceUrl: IDENTITY_SERVICE_URL,
  profileUrl: `https://profile.${OVERRIDE_DOMAIN}`,
  loginUrl: `https://welcome.${OVERRIDE_DOMAIN}`,
}
