import {getLang} from './stringUtils'
import {LANDING_PAGE_REDIRECTIONS, ALL_LANGUAGES} from '../const'
import config from '../config'

export const findRouteToRedirect = url =>
  Object.keys(LANDING_PAGE_REDIRECTIONS).find(route => {
    const urlRegex = new RegExp(`^${config.consentUrl}(/\\?lang=(${ALL_LANGUAGES.join('|')})#)?(/#)?${route}/?$`, 'i')
    return urlRegex.test(url)
  })

export const getLandingPageRoute = (route, lang) => {
  const language = lang || getLang(window.location.search)
  const redirection = LANDING_PAGE_REDIRECTIONS[route]

  const i18nRoute = language && redirection.lang.includes(language) ? `/${language}${redirection.to}` : redirection.to
  return `${config.landingPageURL}${i18nRoute}`
}

export const createSafeLandingPageURL = url => (url && url.startsWith('/') ? `${config.landingPageURL}${url}` : url)
