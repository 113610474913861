const ELEMENTS_IDS = {
  logisterPage: {
    swissIdLogin: 'swiss-id-login-btn',
    facebookLogin: 'facebook-login-btn',
    googleLogin: 'google-login-btn',
    microsoftLogin: 'microsoft-login-btn',
    swissIdInfo: 'swiss-id-info',
    appleLogin: 'apple-login-btn',
    backToBrand: 'back-to-brand',
    continue: 'first-step-continue-btn',
    register: 'first-step-register',
    moreInfo: 'more-info', // class name
    biometricsLogin: 'biometrics-login',
  },
  loginPage: {
    nativeLoginError: 'native-login-error-message',
    invalidNativeValidLegacyPasswordError: 'invalid-native-valid-legacy-password-error',
    loginNative: 'native-login-btn',
    loginNativeCancel: 'native-login-cancel-btn',
  },
  forgetPasswordCaptchaPage: {
    submit: 'forget-password-submit-btn',
    cancel: 'forget-password-cancel-btn',
  },
  forgetPassword: 'forget-password-btn',
  registerNative: 'native-register-btn',
  consentRegister: 'consent-register-btn',
  consentSupport: 'consent-support-btn',
  brandLogo: 'brand-logo', // class name
  languageSwitcher: 'language-switcher', // class name
  emailVerificationPage: {
    resendEmail: 'resend-email',
    continueToLogin: 'continue-to-login',
    submitCaptcha: 'submit-captcha-btn',
    cancelCaptcha: 'cancel-captcha-btn',
  },
  setNewPassword: 'set-new-password-btn',
  profilePage: {
    logout: 'logout-btn',
    resetPassword: 'reset-password-btn',
    updateProfile: {
      general: 'update-general-tab-btn',
      blick: 'update-blick-tab-btn',
      /* TODO: events below are not impelented on GA side */
      wirtschaftsmedien: 'update-wirtschaftsmedien-btn',
      beobachter: 'update-beobachter-tab-btn',
      energy: 'update-energy-tab-btn',
      schweizerillustrierte: 'update-schweizerillustrierte-tab-btn',
      moonandstars: 'update-moonandstars-tab-btn',
      vintage: 'update-vintage-tab-btn',
      rockit: 'update-rockit-tab-btn',
      twentymin: 'update-20min-tab-btn',
      lematin: 'update-lematin-tab-btn',
      pme: 'update-pme-tab-btn',
      illustre: 'update-illustre-tab-btn',
      lessentiel: 'update-lessentiel-tab-btn',
      autodesjahres: 'update-autodesjahres-tab-btn',
      gryps: 'update-gryps-tab-btn',
      cash: 'update-cash-tab-btn',
      caminada: 'update-caminada-tab-btn',
      programmzeitschriften: 'update-programmzeitschriften-tab-btn',
      glueckspost: 'update-glueckspost-tab-btn',
      jobbern: 'update-jobbern-tab-btn',
    },
  },
  footer: {
    impressum: 'impressum',
    dsb: 'dsb',
    agb: 'agb',
    cookieSettings: 'cookie-settings',
    support: 'support',
    about: 'about',
  },
  webauthn: {
    yes: 'webauthn-yes-btn',
    no: 'webauthn-no-btn',
    never: 'webauthn-never-btn',
  },
  mfa: {
    expiredToken: 'mfa-expired-token-cancel-setup-btn',
    returnToProfile: 'mfa-return-to-profile-btn',
    setupCancel: 'mfa-setup-cancel', // class name
    totpActivate: 'mfa-totp-activate-btn',
    totpDeactivate: 'mfa-totp-deactivate-btn',
    totpStart: 'mfa-totp-start-btn',
    totpSetup: 'mfa-totp-setup-btn',
    smsActivate: 'mfa-sms-activate-btn',
    smsDeactivate: 'mfa-sms-deactivate-btn',
    smsSetupSubmitMobileNumber: 'mfa-sms-setup-submit-mobile-number-btn',
    smsSetupSubmitVerificationCode: 'mfa-sms-setup-submit-verification-code-btn',
    recoveryCodesGenerate: 'mfa-recovery-codes-generate-btn',
    recoveryCodesViewed: 'mfa-recovery-codes-viewed-btn',
    verificationCancel: 'mfa-verification-cancel-btn',
    verificationVerify: 'mfa-verification-verify-btn',
  },
}

export {ELEMENTS_IDS}
