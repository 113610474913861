import {ELEMENTS_IDS} from './elementIds'
import {isLocal, isDev, getDeployEnv} from 'utils/envUtils'

const PAGE_TYPES = {
  CONSENT: 'consent',
  PROFILE: 'profile',
}

const BRANDS = {
  blick: 'blick',
  energy: 'energy',
  beobachter: 'beobachter',
  wirtschaftsmedien: 'wirtschaftsmedien',
  schweizerillustrierte: 'schweizerillustrierte',
  moonandstars: 'moonandstars',
  vintage: 'vintage',
  schlager: 'schlager',
  twentymin: '20min',
  rockit: 'rockit',
  testing: 'testing',
  pme: 'pme',
  illustre: 'illustre',
  lematin: 'lematin',
  onelog: 'onelog',
  baz: 'baz',
  berneroberlander: 'berneroberlander',
  bernerzeitung: 'bernerzeitung',
  bilan: 'bilan',
  derbund: 'derbund',
  derlandbote: 'derlandbote',
  finanzundwirtschaft: 'finanzundwirtschaft',
  langenthalertagblatt: 'langenthalertagblatt',
  lematindimanche: 'lematindimanche',
  schweizerfamilie: 'schweizerfamilie',
  sihltaler: 'sihltaler',
  sonntagszeitung: 'sonntagszeitung',
  tagesanzeiger: 'tagesanzeiger',
  tamedia: 'tamedia',
  thalwileranzeiger: 'thalwileranzeiger',
  thunertagblatt: 'thunertagblatt',
  tribunedegeneve: 'tribunedegeneve',
  twelveapp: '12app',
  twentyfourhours: '24hours',
  zurcherunterlander: 'zurcherunterlander',
  zurichseezeitung: 'zurichseezeitung',
  srg: 'srg',
  lessentiel: 'lessentiel',
  cash: 'cash',
  interview: 'interview',
  jobs: 'jobs',
  jobup: 'jobup',
  redsport: 'redsport',
  gryps: 'gryps',
  caminada: 'caminada',
  glueckspost: 'glueckspost',
  programmzeitschriften: 'programmzeitschriften',
  landliebe: 'landliebe',
  zff: 'zff',
  nzz: 'nzz',
  nzzmagazin: 'nzzmagazin',
  aargauerzeitung: 'aargauerzeitung',
  jobscout24: 'jobscout24',
  ticketcorner: 'ticketcorner',
  jobbern: 'jobbern',
  ostjob: 'ostjob',
  zentraljob: 'zentraljob',
  autodesjahres: 'autodesjahres',
  watson: 'watson',
}

const TAMEDIA_BRANDS = [
  BRANDS.twelveapp,
  BRANDS.twentyfourhours,
  BRANDS.baz,
  BRANDS.berneroberlander,
  BRANDS.bernerzeitung,
  BRANDS.bilan,
  BRANDS.derbund,
  BRANDS.derlandbote,
  BRANDS.finanzundwirtschaft,
  BRANDS.langenthalertagblatt,
  BRANDS.lematindimanche,
  BRANDS.schweizerfamilie,
  BRANDS.sihltaler,
  BRANDS.sonntagszeitung,
  BRANDS.tagesanzeiger,
  BRANDS.tamedia,
  BRANDS.thalwileranzeiger,
  BRANDS.thunertagblatt,
  BRANDS.tribunedegeneve,
  BRANDS.zurcherunterlander,
  BRANDS.zurichseezeitung,
]

export const TX_GROUP_BRANDS = [...TAMEDIA_BRANDS, BRANDS.twentymin, BRANDS.lematin, BRANDS.lessentiel]

// brands that are not implemented into SSO but have mentions here
const BRAND_PARTNERS = {
  bilanz: 'bilanz',
  handelszeitung: 'handelszeitung',
  tele: 'tele',
  tvstar: 'tvstar',
  tvvier: 'tvvier',
}

const FEDERATED_PROVIDERS = {
  google: {
    name: 'google',
    formattedName: 'Google',
    icon: 'icon-google-24x24.svg',
  },
  apple: {
    name: 'apple',
    formattedName: 'Apple',
    icon: 'logo-apple-black.svg',
  },
  swissid: {
    name: 'swissid',
    formattedName: 'SwissID',
    icon: 'swiss_id_rot.png',
  },
  facebook: {
    name: 'facebook',
    formattedName: 'Facebook',
    icon: 'icon-facebook-24x24.svg',
  },
  microsoft: {
    name: 'microsoft',
    formattedName: 'Microsoft',
    icon: 'icon-microsoft-24x24.svg',
  },
}

const LOGIN_CASES = {
  DEFAULT: 'default',
  GENERAL: 'general',
  AUTOCOMPLETE: 'autocomplete',
  ENERGY: {
    EVENT: 'event',
  },
  EMAIL_ONLY: 'email_only',
  CHECKOUT: 'checkout',
  NEWSLETTER: 'newsletter',
  BLICK: {
    BLICKPLUS_PIANO: 'blickplus_piano',
    BLICKPLUS_ABOSHOP: 'blickplus_aboshop',
    BLICKPRINT_ABOSHOP: 'blickprint_aboshop',
    SUMMITS: 'summits',
    EM_GAME: 'em_game',
    SKI_TROPHY_GAME: 'ski_trophy_game',
    WINTER_AWARDS: 'winter_awards',
    MEDAILLEN_TROPHY_GAME: 'medaillen_trophy_game',
    ICEHOCKEY_GAME: 'game_icehockey',
    EM_2022_GAME: 'em2022_trophy_game',
    ESAF_2022_GAME: 'esaf2022_trophy_game',
    SKI_TROPHY_2223_GAME: 'ski_trophy_2223_game',
    UNIHOCKEY_WM_2022_GAME: 'unihockey_wm_2022_game',
    SOCCER_WM_2022_GAME: 'soccer_wm_2022_game',
    JACKPOT_2023_GAME: 'jackpot_2023_game',
    EISHOCKEY_WM_2023_GAME: 'eishockey_wm_2023_game',
    EMAIL_ONLY_MYSERVICE: 'email_only_myservice',
    BLICKPLUS_SPONSORING: 'blickplus_sponsoring',
  },
  TWENTYMIN: {
    GENERAL: 'general',
    GAME: 'game',
    GAME_PFISTER: 'game_pfister',
    GAME_QATAR: 'game_qatar',
    GAME_SAMSUNGSMARTTV: 'game_samsungsmarttv',
    GAME_FLUGTAG: 'game_flugtag',
    GAME_LSM: 'game_lsm',
    GAME_FESTIVALAROSA: 'game_festivalarosa',
    GAME_RENDEZVOUS: 'game_rendezvous',
    GAME_MONATSMIETE2: 'game_monatsmiete2',
    GAME_RICARDOBUS: 'game_ricardobus',
    GAME_FOO_FIGHTERS: 'game_foo_fighters',
  },
  WIRTSCHAFTSMEDIEN: {
    STUDENTENABO: 'studentenabo',
  },
  BEO: {
    BEOPLUS: 'beoplus',
  },
}

const PROFILE_TABS = {
  ...BRANDS,
  global: 'global.profile',
  onelog: 'global.profile',
  globalAddress: 'global.address',
  globalSecurity: 'global.security',
}

const GP_NUMBER_SUPPORT = {
  [BRANDS.wirtschaftsmedien]: 'https://www.hznews.ch/service/kontaktieren-sie-unseren-kundenservice',
  [BRANDS.beobachter]: 'https://shop.beobachter.ch/kundenservice',
  [BRANDS.schweizerillustrierte]: 'https://www.schweizer-illustrierte.ch/kontakt',
  [BRANDS.pme]: 'https://boutique.pme.ch/gestion-abonnement',
  [BRANDS.illustre]: 'https://boutique.illustre.ch/gestion-abonnement',
}

// TODO merge ID_LIST and CLIENT_MAP from theme/brandUtils
const ID_LIST = (() => {
  if (isDev() || isLocal()) {
    return {
      [PROFILE_TABS.beobachter]: 'KWljlSzbgIC0u2x6jTfVZFPFeG3nTXQ1',
      [PROFILE_TABS.blick]: 'u8IACmeHfJx9UWZlnUoUmFHy9MRVBTUG',
      [PROFILE_TABS.energy]: 'k5aG4M1VCe16wu1qFGaPe7lqt23PYysk',
      [PROFILE_TABS.wirtschaftsmedien]: 'Krg9em3ifSUkwsAT4iQ0OAnKmD5JGCBM',
      [PROFILE_TABS.schweizerillustrierte]: 'Z2uuDDOlSLUBBq2K7yfVGavR5D5n3V5b',
      [PROFILE_TABS.moonandstars]: '10abeb0f5f564454bdbb035ea252bcd9',
      [PROFILE_TABS.vintage]: '41ce1c7b5aef4f10a919dcb469d7147d',
      [PROFILE_TABS.schlager]: '3217ef0b08d44064a744c7290aeffb7c',
      [PROFILE_TABS.rockit]: '927cdde47ec44a2886a6b62a0fa7722f',
      [PROFILE_TABS.twentymin]: '7H7IGMgD0E5qhXNpOJKD6Vzn1l0AQv9o',
      [PROFILE_TABS.testing]: 'IftqiKbpzK7mljcr2l0BSYj8fw0CmbCx',
      [PROFILE_TABS.illustre]: '28abf9acd473fb600c29c7a8f5be6940',
      [PROFILE_TABS.pme]: '69342b34d1e2ad30abfd8cac65e317d4',
      [PROFILE_TABS.lematin]: '5acee1cbfcf930e1b959745c4af5cf33',
      [PROFILE_TABS.baz]: 'f4a2f5439aba465b1605ec5f687f8ad9',
      [PROFILE_TABS.berneroberlander]: 'cef9ff09231a4932992ab990876c2709',
      [PROFILE_TABS.bernerzeitung]: '52d7640f9f374621a87a4e2d0b216d28',
      [PROFILE_TABS.bilan]: '56b7d54f480644bc9eb22fccb8b5b09d',
      [PROFILE_TABS.derbund]: '8cc827d7279f4752b843b9ff0169db53',
      [PROFILE_TABS.derlandbote]: 'abeef5e9298c4517a83190b5882faf28',
      [PROFILE_TABS.finanzundwirtschaft]: 'fc004ae8791e4519a5ceef7d6fabd3b7',
      [PROFILE_TABS.langenthalertagblatt]: 'a1ca52e5b2e614462b4dcb57b2fcc044',
      [PROFILE_TABS.lematindimanche]: 'bcb9fd18378a4c68806d4dd02f6a7647',
      [PROFILE_TABS.schweizerfamilie]: 'fec756513e75460080996fe3b961fa41',
      [PROFILE_TABS.sihltaler]: 'be79cf5093c74c378594d23490121eb8',
      [PROFILE_TABS.sonntagszeitung]: '5f9b3ef0178a426da750082c0dbe911c',
      [PROFILE_TABS.tagesanzeiger]: '819f0e7374bd0dd0796a4b2b8f28db9a',
      [PROFILE_TABS.tamedia]: 'e38f68cd83dc4a3c9c0811b394950920',
      [PROFILE_TABS.thalwileranzeiger]: 'c1ef9e5aa90346388502be9e6cb0e1a3',
      [PROFILE_TABS.thunertagblatt]: 'cdce93c26feb4e0b988551345862fe35',
      [PROFILE_TABS.tribunedegeneve]: 'bcfac6d8ec4946c6b113c7d91c9733aa',
      [PROFILE_TABS.twelveapp]: 'fd4de3bc532734c82bbae92e7447fbf8',
      [PROFILE_TABS.twentyfourhours]: '41018b8a2972fbe3ffa8096aeb4ec131',
      [PROFILE_TABS.zurcherunterlander]: 'eaac5b30d6fd41a5a8877dbeae376662',
      [PROFILE_TABS.zurichseezeitung]: '68d00bd0e47f466bbde15437d45b50f3',
      [PROFILE_TABS.global]: '154a5e0ad499a32812fd8372fd26fe34',
      [PROFILE_TABS.lessentiel]: '59179c00f25dc6eb00623315e8d080b0',
      [PROFILE_TABS.cash]: 'a36b748c4897b57495563f0343954f47',
      [PROFILE_TABS.interview]: '0c93e5af42476f837777894736c558db',
      [PROFILE_TABS.jobs]: '2a632db5b331ee65764a0813c058e441',
      [PROFILE_TABS.jobup]: 'b05b6aa1d2e34ae7b8d03035d6c18a77',
      [PROFILE_TABS.redsport]: '0a288772bbbdf8873c9c4710f396dc20',
      [PROFILE_TABS.gryps]: 'bc70e4f3f105de9fb64e549d825061d5',
      [PROFILE_TABS.caminada]: 'd762a9a95d5aa3184baaafb91be84e13',
      [PROFILE_TABS.glueckspost]: '46157626c2faf78e1a4e8bcc748e0b70',
      [PROFILE_TABS.programmzeitschriften]: '236434ebeafb9e86c71318853be04599',
      [PROFILE_TABS.landliebe]: '8f6f33b45a2747f75a9ea2472acd5b41',
      [PROFILE_TABS.zff]: '942bd752b4374e4173b91ade2679189c',
      [PROFILE_TABS.aargauerzeitung]: '50947d981a1cebb8bf53cd61534a07a4',
      [PROFILE_TABS.jobscout24]: 'dbde0217454783fa16a6b0ab6299df12',
      [PROFILE_TABS.ticketcorner]: 'f1290ddba24b17694ca1a486699fbc48',
      [PROFILE_TABS.jobbern]: '63e3ddbaa9252655900be4f5e45dad3c',
      [PROFILE_TABS.autodesjahres]: '36151374f85001aedb47e51a6fd9a14f',
      [PROFILE_TABS.nzz]: '2aaa97f18c7978be64a3fad0e128ea33',
      [PROFILE_TABS.watson]: '200da3c222344aa51fbb5aaf7e97567c',
    }
  }

  return {
    stg: {
      // [PROFILE_TABS.beobachter]: 'U3LIrxgEqO4MmENm841ckx9Psrede2DH',
      [PROFILE_TABS.beobachter]: '5e6f0dd338934b283ea3588dbf902d35',
      // [PROFILE_TABS.blick]: 'QiQsPyS8NNexn6BGbIdfVAyGaihbDsrP',
      [PROFILE_TABS.blick]: '0a41bc5ff18efc3851032dfe24c1408f',
      // [PROFILE_TABS.energy]: 'oAWZakeaP56R8wJIEpEKIkjuPWAbEMnz',
      [PROFILE_TABS.energy]: '771634f140ba7c8f144c248f4f4a7417',
      // [PROFILE_TABS.wirtschaftsmedien]: 'v1g9SBq0gODHcZHAb075yRt5CK7e4MMy',
      [PROFILE_TABS.wirtschaftsmedien]: 'b08dac916d575ce0b7328c9f210fa3cd',
      // [PROFILE_TABS.schweizerillustrierte]: 'g2ktFcBXjMxMEiRs1V5hP5Jv2tYI2ZG9',
      [PROFILE_TABS.schweizerillustrierte]: '44a7fb4af34f792a3733dcbae5437f02',
      // [PROFILE_TABS.moonandstars]: '44375c6d5325453d82a9b6ae6f4419f9',
      [PROFILE_TABS.moonandstars]: '846627c0580d9e932834600d762366d3',
      // [PROFILE_TABS.vintage]: '9bb2ba3934234b93b93bb2fc9d98c590',
      [PROFILE_TABS.vintage]: '930b7f3dc73b7efcb7788218e6905135',
      // [PROFILE_TABS.schlager]: 'bbcd84df042f4a9fb573e97549390780',
      [PROFILE_TABS.schlager]: 'fcc0f1fc162b47b88c75900f39d88ce5',
      // [PROFILE_TABS.rockit]: 'f079540cc46a443f934c2ea92e637fba',
      [PROFILE_TABS.rockit]: '138db04bdcc9b5132dd1af816ddac55c',
      // [PROFILE_TABS.twentymin]: 'YwmA3uKi8x7YAe3ttesGum95vD1DB8LY',
      [PROFILE_TABS.twentymin]: '0b6dc07134a4b6306b574bea64b0cd9d',
      [PROFILE_TABS.testing]: 'IftqiKbpzK7mljcr2l0BSYj8fw0CmbCx',
      // [PROFILE_TABS.illustre]: '96f1e4511935ab7e3eda42e8b63dcce9',
      [PROFILE_TABS.illustre]: '8964195f56dd1345a560702ca539e262',
      // [PROFILE_TABS.pme]: '44a7a0d44c3201fe514b351c27bd06e3',
      [PROFILE_TABS.pme]: '5066ef4a0fe2b0f25ee3afacd1db0d52',
      // [PROFILE_TABS.lematin]: '43a66cc5a75120dcdbe8d76a2ff4e69b',
      [PROFILE_TABS.lematin]: '2ac728284bcc908c340016ae40522ce4',
      // [PROFILE_TABS.baz]: 'bdc9c55e5703187a2fd70f0f03de9d6f',
      [PROFILE_TABS.baz]: '566d1b9e6ee92f58982da16adb43cd48',
      // [PROFILE_TABS.berneroberlander]: '011ba75b8cc64c5a846c47065b0fa999',
      [PROFILE_TABS.berneroberlander]: 'f533589e59d44e531857dec5eb27b5a0',
      // [PROFILE_TABS.bernerzeitung]: '2ca18129fcdd4db0a7720f876954bfa2',
      [PROFILE_TABS.bernerzeitung]: 'cc33f5f51e68795da40937255924b392',
      // [PROFILE_TABS.bilan]: '98db9fb846c54631b9735bd710f1c448',
      [PROFILE_TABS.bilan]: 'b42849054a4f1402e7c1c92095de78ce',
      // [PROFILE_TABS.derbund]: '41cfef4567944071a3c5124813f8afba',
      [PROFILE_TABS.derbund]: 'f28e745d1e19ea794dbc83ea51ec9741',
      // [PROFILE_TABS.derlandbote]: '4177af1e60a341e791881f22d233591c',
      [PROFILE_TABS.derlandbote]: 'f8c5bda6e3f36da7b21bf2594dcefb5f',
      // [PROFILE_TABS.finanzundwirtschaft]: 'b1d6be20750d4a23b4ad46888e2be5ed',
      [PROFILE_TABS.finanzundwirtschaft]: '68602dad3994ace187baf68c5ec76e5e',
      // [PROFILE_TABS.langenthalertagblatt]: '4d3d18d1254cad2e1417d8ce4992db28',
      [PROFILE_TABS.langenthalertagblatt]: '0c96dd0df7d429c6d2a66571db89a0ad',
      // [PROFILE_TABS.lematindimanche]: 'f3dba82226a340988ca4eefdc8fb64f8',
      [PROFILE_TABS.lematindimanche]: '4a2eb502d490fdf9db8453330529af02',
      // [PROFILE_TABS.schweizerfamilie]: 'bfaceec0dc3a49e0992127b5c7f197f0',
      [PROFILE_TABS.schweizerfamilie]: 'e5d8931c3dd39b6f6976131eefecf50e',
      // [PROFILE_TABS.sihltaler]: 'ca82ead3c7f344c3a0539e3609a1aba6',
      [PROFILE_TABS.sihltaler]: '747c2efa0ebcae5d8504ae457f8ac5ce',
      // [PROFILE_TABS.sonntagszeitung]: 'ad80fc0b94674222bbd607ce914f828a',
      [PROFILE_TABS.sonntagszeitung]: 'b65c17d2dae254567c8f12883b397988',
      // [PROFILE_TABS.tagesanzeiger]: '938b2cf5d3cf5aaa2b25786ad04248fd',
      [PROFILE_TABS.tagesanzeiger]: 'c4bb5fe759cf8dfbc1ff9ae38331a574',
      // [PROFILE_TABS.tamedia]: 'd68b3bcc96ba4798b6a36d558718dd98',
      [PROFILE_TABS.tamedia]: 'f6f28120bb592f82905a3d1ead88292e',
      // [PROFILE_TABS.thalwileranzeiger]: 'a9e5b95a9cab4bb1a9311b49f445dabb',
      [PROFILE_TABS.thalwileranzeiger]: '24eeb4b68057eede1b8154bd773862e7',
      // [PROFILE_TABS.thunertagblatt]: '15fad9398e474df09bf0ea97bc91e02b',
      [PROFILE_TABS.thunertagblatt]: '1852ff00552617f2e0c00b062c060da7',
      // [PROFILE_TABS.tribunedegeneve]: 'bae5fb2b3be9418996fc0d865e2bdf54',
      [PROFILE_TABS.tribunedegeneve]: '444b2d2a2c4b1229201b7ae9bc93a21e',
      // [PROFILE_TABS.twelveapp]: 'cd7950f0044a6c633680b642e980b8f4',
      [PROFILE_TABS.twelveapp]: '2f1bfeb5d91ffeeb7d6bc6425cfae75c',
      // [PROFILE_TABS.twentyfourhours]: 'd2732c773e7790e4096f27883ca605f9',
      [PROFILE_TABS.twentyfourhours]: '4296319a268257dab44d5a136a562420',
      // [PROFILE_TABS.zurcherunterlander]: '0b323e9807234541bc28087acc1bb6b3',
      [PROFILE_TABS.zurcherunterlander]: 'b4de2dc88d3db4a8fa51f22781e3818c',
      // [PROFILE_TABS.zurichseezeitung]: '9ede1cfd396641ca92cd4763cb0131e0',
      [PROFILE_TABS.zurichseezeitung]: 'b2d356cd3ee154a359f09b20ab060dcd',
      [PROFILE_TABS.global]: '1faef48d1301ac9b0a7e7a7e81c96151',
      // [PROFILE_TABS.lessentiel]: '63d18137defc7e576d5b81a10548d32c',
      [PROFILE_TABS.lessentiel]: '1ff3ec1a01ae69184433cc8694b10bed',
      // [PROFILE_TABS.cash]: 'b63498d1a6c2d36ef629db17fe0af773',
      [PROFILE_TABS.cash]: 'd1c1f83b0353a0c19ffff39e2c4e3180',
      // [PROFILE_TABS.interview]: '023ecdfa42ae3ad13e7b30242ce7a5fd',
      [PROFILE_TABS.interview]: '54ce92d682ae9bb5b790d4bc6c00fddd',
      // [PROFILE_TABS.jobs]: 'c79b67414c94db2f88f805fcf50b8d0b',
      [PROFILE_TABS.jobs]: '6d15f2934aeb1858694b4c281e0f15ee',
      // [PROFILE_TABS.jobup]: 'a2988719b2976ebb796bbe8455e35221',
      [PROFILE_TABS.jobup]: '0dcc5d8ca116982f28bdb1dedbbe81fa',
      [PROFILE_TABS.redsport]: '79d0ad67dff62154b81cd25ac4dbbb4a',
      // [PROFILE_TABS.gryps]: 'd477673c21d624cbf26e7f16dd2758c8',
      [PROFILE_TABS.gryps]: '2e82663b1319ab9f4484c75204f99e3a',
      // [PROFILE_TABS.caminada]: 'e87027e6dbf89be9ed5d20180d47a014',
      [PROFILE_TABS.caminada]: '5d73273b7860bac6a35c0d5df5d77ff7',
      // [PROFILE_TABS.glueckspost]: '79cb2e3a15285a0cdae95857445c162e',
      [PROFILE_TABS.glueckspost]: 'e05cd5c0057917d371d1a50204379f50',
      // [PROFILE_TABS.programmzeitschriften]: 'd8dc6867f2a9628e65fe02b169415a50',
      [PROFILE_TABS.programmzeitschriften]: 'fddf3fb9cbdfe27a29d3b750c8381dbc',
      // [PROFILE_TABS.landliebe]: '12c7df1599d30916064a46977fa41198',
      [PROFILE_TABS.landliebe]: '16ad03078e12cbbff9157782b981bf66',
      // [PROFILE_TABS.zff]: '82763c6353f1e2c45f6b2b4f653468fb',
      [PROFILE_TABS.zff]: '05e64cf3c8997f2c29daf3f5e68e6f89',
      // [PROFILE_TABS.aargauerzeitung]: '3c18f8ab386885eb12dd3b7c167e44e2',
      [PROFILE_TABS.aargauerzeitung]: 'b3af58355b75110ca7544fa67b723fbc',
      [PROFILE_TABS.jobscout24]: '571b3486ad31a2cd4615fc485dcf97d0',
      [PROFILE_TABS.ticketcorner]: 'd223b1d579ed06e1405abca158cd9ce9',
      [PROFILE_TABS.jobbern]: '501c92d8ec2d800c4e72326cb4adbd99',
      // [PROFILE_TABS.autodesjahres]: '9b5cca74a5529ee1d41acde7c1478496',
      [PROFILE_TABS.autodesjahres]: '2886db410a8c721857a533a1b4de40cc',
      // [PROFILE_TABS.nzz]: 'd65e4f666e134384fccd3807db117938',
      [PROFILE_TABS.nzz]: 'f247468c5cfb86e25bd9eefbf27abb27',
      [PROFILE_TABS.watson]: 'd5964d8fde387a0d4cede0f4400f49c9',
    },
    prod: {
      [PROFILE_TABS.beobachter]: '8ab7a1bbe02c0dd8ec62b40ab57fc2f7',
      // [PROFILE_TABS.blick]: 'iq6JnMWfrXwdLWQxNSPzlCChFaMI2O9B',
      [PROFILE_TABS.blick]: '6d8dcfe7824c089600ba85267a6f5ec4',
      // [PROFILE_TABS.energy]: 'JWT9GBjMcaJ7dn8FCzZ7SVESpgYj98nq',
      [PROFILE_TABS.energy]: 'f19435886ae1797a15d0fdc17dcc6b6a',
      // [PROFILE_TABS.wirtschaftsmedien]: 'PfD5ANcJ4rfRxbfSyw4LUumfSJgS2TVu',
      [PROFILE_TABS.wirtschaftsmedien]: '06d811a7d7de3927c19f2cfabd362900',
      // [PROFILE_TABS.schweizerillustrierte]: 'HbJvGoqmxtd5gHekAul7gbPIiV8oJ5UC',
      [PROFILE_TABS.schweizerillustrierte]: '02359a4a6a1f1dedce936e4ea55f1230',
      // [PROFILE_TABS.moonandstars]: '586884bbc46847ecbf54a22206cf47c4',
      [PROFILE_TABS.moonandstars]: 'aff258ebb512f976e9baafd11a2ac7eb',
      // [PROFILE_TABS.vintage]: 'a087b5fe8e0542e69d87ee88c5dcb0f1',
      [PROFILE_TABS.vintage]: '16d91603cf569cc6c5beaa0070d1523f',
      // [PROFILE_TABS.schlager]: '6cf466a3923945d68d4296470e75cecf',
      [PROFILE_TABS.schlager]: '7fc5cfe2757256b19be025ce981d8f39',
      // [PROFILE_TABS.rockit]: '10432f66684d40f3b13c65e35d7997f8',
      [PROFILE_TABS.rockit]: '544b1eba052bd8c429e1655cc7ba7d2c',
      // [PROFILE_TABS.twentymin]: 'OtYl4MH20En41QB5dYjcI5v9mzLv0FVh',
      [PROFILE_TABS.twentymin]: '2e3e2e31a9f1d31a22d2a302dbbdfcd4',
      [PROFILE_TABS.testing]: '54be4411ffda35fcfdc0612879464895',
      // [PROFILE_TABS.illustre]: '5845ce667e78c34fd4ee724b95fb4834',
      [PROFILE_TABS.illustre]: '1fa66346ef33bc33618bbe51ad1e4992',
      // [PROFILE_TABS.pme]: '551faa4c61865c2e3e9db5077c08788b',
      [PROFILE_TABS.pme]: '3e4cce2b66a769eaff45fc63dd9ef3af',
      // [PROFILE_TABS.lematin]: '1231bed416732d6139a20057dcc4569d',
      [PROFILE_TABS.lematin]: '368077a19b6b377e6e1fadf1c5e5ca3c',
      // [PROFILE_TABS.berneroberlander]: 'e631310d5aec4315bb599e850bfc7557',
      [PROFILE_TABS.berneroberlander]: 'b638408c70dd47d2b35f0ba008295358',
      // [PROFILE_TABS.bernerzeitung]: 'e9fa390d486c49648aef7f3b9d426805',
      [PROFILE_TABS.bernerzeitung]: '9e95031d012266759d6f7337f22d38ce',
      // [PROFILE_TABS.bilan]: '30dc2b69e18c4c35bc616f63c35defab',
      [PROFILE_TABS.bilan]: '6dca3be2116f3a39ada918e58b277fc7',
      // [PROFILE_TABS.derbund]: '53650b07630c4fcdbc35e568821c89ba',
      [PROFILE_TABS.derbund]: '670d6a9b1dec3ae90ef5322c63e5b637',
      // [PROFILE_TABS.derlandbote]: '98076f4f32ed4aafaf397951a83db652',
      [PROFILE_TABS.derlandbote]: 'aa79d7627ab918759aa89ccab49cd9ac',
      // [PROFILE_TABS.finanzundwirtschaft]: '6fdd2d6a6a004057b31b6c65ddcec938',
      [PROFILE_TABS.finanzundwirtschaft]: '0ef8694c1c58f9f72a3eef068ce98993',
      // [PROFILE_TABS.langenthalertagblatt]: '45937c8b1d76d66eff9213cc916f9dc4',
      [PROFILE_TABS.langenthalertagblatt]: '584ebef250093c992df983e178d1792b',
      // [PROFILE_TABS.lematindimanche]: '0f495fd633ae4f5f8d82ad8a49a9fe77',
      [PROFILE_TABS.lematindimanche]: '3c7d1f360d9c99cc8ef6d7e5ed7dccac',
      // [PROFILE_TABS.schweizerfamilie]: 'ae84a46098354d13a86d2236a4d1fab7',
      [PROFILE_TABS.schweizerfamilie]: '295b30ded23c3ec0dde6a1b478e3e709',
      // [PROFILE_TABS.sihltaler]: '2f73890327204e3e86540a557de20283',
      [PROFILE_TABS.sihltaler]: 'eefb2c027c609ab0dd8a46061c55adb7',
      // [PROFILE_TABS.sonntagszeitung]: 'dea248e85a9e4018a5112160517ea06f',
      [PROFILE_TABS.sonntagszeitung]: 'fca436b42e67102222611b1cdd75f184',
      // [PROFILE_TABS.tagesanzeiger]: '323ebaeb98c4632899dbe609bb5675b5',
      [PROFILE_TABS.tagesanzeiger]: '4e4700cc743745e47ad3d5da361691e5',
      // [PROFILE_TABS.tamedia]: 'cc0aafbaecbf41f2b8031aed4c18aa6e',
      [PROFILE_TABS.tamedia]: '15d5d9c4e0037735605dcbedb4fd5ab9',
      // [PROFILE_TABS.thalwileranzeiger]: '82187be2068f412b9c94246bc3ed20e3',
      [PROFILE_TABS.thalwileranzeiger]: '30377368848e98ca13d3b0a6c779e0e7',
      // [PROFILE_TABS.thunertagblatt]: 'df445698471c45cb988b908761c51eb7',
      [PROFILE_TABS.thunertagblatt]: 'd259b82f9a312a0cbaa924bbf4bcd78d',
      // [PROFILE_TABS.tribunedegeneve]: '9e47037491474eea8a1d0147eb2a6bb8',
      [PROFILE_TABS.tribunedegeneve]: '311c587a20a6efcd18d15192ab9d41fc',
      // [PROFILE_TABS.twelveapp]: '8468b87a86843f626f237e3c19149947',
      [PROFILE_TABS.twelveapp]: '98cd4f3baa85a75124ca2438e3a08698',
      // [PROFILE_TABS.twentyfourhours]: '27291f6fbd24b4f2efde09ead1eaaabd',
      [PROFILE_TABS.twentyfourhours]: '01219ad7ba1c7fd3e408ad43911ea708',
      // [PROFILE_TABS.zurcherunterlander]: '5e20548491554fb2a04ce120b0282e23',
      [PROFILE_TABS.zurcherunterlander]: '69e4ad5c9501986586473d87e5966b1d',
      // [PROFILE_TABS.zurichseezeitung]: 'd2c0c062fb1840f8a86d29b52e7a481b',
      [PROFILE_TABS.zurichseezeitung]: 'dfac4f67bb2895ff69623f1e8145060f',
      // [PROFILE_TABS.baz]: '0afe65bedea7e106513eabe15c2fbb95',
      [PROFILE_TABS.baz]: 'aac9d202dfb5a2a59ee3d83780736fe5',
      // [PROFILE_TABS.lessentiel]: '4809b195aa9d12efbe46ec1a722fe2b5',
      [PROFILE_TABS.lessentiel]: 'b2cb291babbe5adae8314717e1d11afc',
      // [PROFILE_TABS.cash]: 'c07a14a66268a1af6428b7c50c17bd67',
      [PROFILE_TABS.cash]: '33ead33a1b2dd3cffeeb532ca36c5453',
      // [PROFILE_TABS.interview]: '44f944fc4eac9cc133293d7260f0b6bb',
      [PROFILE_TABS.interview]: '82b964631e7aa4667dba55ed0c9f3e2a',
      // [PROFILE_TABS.jobs]: '937572930ad0d66ba59031893b6be0a5',
      [PROFILE_TABS.jobs]: '7c8842484d5de80e9620fa808c9737d0',
      // [PROFILE_TABS.jobup]: '0c6eeb09f92b792c4ab273d461d00033',
      [PROFILE_TABS.jobup]: 'c4ae2648c83a7e30c537b80ab8ac8f6d',
      [PROFILE_TABS.redsport]: '4a2f57a074147694672faaa08b9c2b9e',
      // [PROFILE_TABS.gryps]: '2ead33eead8b106e6f7e7f46615aeec3',
      [PROFILE_TABS.gryps]: 'cad5f41819841bfbac937a8e97ec4b22',
      // [PROFILE_TABS.caminada]: 'dfade7addebd5ac14b14c27a2f975572',
      [PROFILE_TABS.caminada]: '159c4c56beaaa9125b1527a053c5f516',
      // [PROFILE_TABS.glueckspost]: 'f5d973a9aec8726d17ecc79634a26ead',
      [PROFILE_TABS.glueckspost]: '03f0984f8d4523885ae3c803e792426a',
      // [PROFILE_TABS.programmzeitschriften]: '320eed07a53892c6cfa75ac59f2ed6af',
      [PROFILE_TABS.programmzeitschriften]: 'ebe329b80d46913836249483b97cdab6',
      // [PROFILE_TABS.landliebe]: 'f1ca4af6f6753dcfba98dde97145b164',
      [PROFILE_TABS.landliebe]: 'f5c5b82e89a96e9b9fb92d61681eb75c',
      // [PROFILE_TABS.zff]: 'bfb4ab7199750d2ece16e75b3da36088',
      [PROFILE_TABS.zff]: 'a3960b0ac939ba9341d791c1321972fa',
      // [PROFILE_TABS.aargauerzeitung]: '4dcf2ddd3c7994d9b6034d1359d554a8',
      [PROFILE_TABS.aargauerzeitung]: '8af253cbac6000a4e8c97793a84c19a9',
      [PROFILE_TABS.jobscout24]: '2719bc61786ef6e44fd2abc60f44f603',
      [PROFILE_TABS.ticketcorner]: '41e9926d509760247bf3264a849e703d',
      [PROFILE_TABS.jobbern]: '4c88caf749abf92040decdf8cac1f116',
      // [PROFILE_TABS.autodesjahres]: '91c1888367c0ba92cfab2b7a4b87dd08',
      [PROFILE_TABS.autodesjahres]: '40a935a6d12f75987a48a9dba964acdb',
      // [PROFILE_TABS.nzz]: '57258093d521bdeeb1e58f92ec1179d5',
      [PROFILE_TABS.nzz]: 'f247468c5cfb86e25bd9eefbf27abb27',
      [PROFILE_TABS.watson]: 'bc7730be0eef3db50b7e0e79b3b9226f',
    },
  }[getDeployEnv()]
})()

const INTERACTION_UID = 'uid_storage_key'

const NATIVE_IDENTITY_PREFIX = 'native'

const PROFILE_SUPPORT_BY_BRAND = {
  [BRANDS.energy]: 'https://energy.ch/kontakt',
  [BRANDS.moonandstars]: 'https://moonandstars.ch/de/contact',
  [BRANDS.vintage]: 'https://vintageradio.ch/kontakt/',
  [BRANDS.schlager]: 'https://schlagerradio.ch/kontakt/',
  [BRANDS.rockit]: 'https://rockitradio.ch/kontakt/',
}

const WEB_AUTHN_CREDENTIALS_LOCAL_STORAGE_KEY = 'web_authn_credentials'
const WEB_AUTHN_ACCOUNTS_COOKIE_NAME = 'web_authn_accounts'
const SKIP_WEB_AUTHN_SETUP = 'skip_web_authn_setup'

const FACE_ID = 'faceId'
const TOUCH_ID = 'touchId'
const ANY = 'anyId'

const LOGIN_HINT_CHECK_ERROR = 'login_hint_check'
const EMAIL_FORCE_CHECK_ERROR = 'email_force_check'

const BIOMETRIC_TYPES = [FACE_ID, TOUCH_ID, ANY]

const POST_REDIRECT_ACTION = 'post_redirect_action'

const POST_REDIRECT_MODAL_TYPES = {
  DEACTIVATION: 'DEACTIVATION',
  GENERATION_CODES_CONFIRMATION: 'GENERATION_CODES_CONFIRMATION',
  CREDENTIAL_REMOVE: 'CREDENTIAL_REMOVE',
  IDENTITY_REMOVE: 'IDENTITY_REMOVE',
}

const TOTP_SETUP_VIEWS = {
  QR_CODE: 'QR_CODE',
  LINKS: 'LINKS',
  CODE: 'CODE',
}

const BRAND_IDS = {
  ONELOG: 0,
}

const EMAIL_CHANGE_VERIFICATION_STATUS = {
  NOT_PURE_NATIVE: 'not_pure_native',
  EMAIL_EXISTS: 'email_exists',
  INVALID_TICKET: 'invalid_ticket',
  SUCCESS: 'email_verify_success',
}

const MOBILE_NUMBER_INTEGRITY_CHECK_FIELD_NAME = 'mobileNumberIntegrityCheckValue'

const LANGUAGES = {
  DE: 'de',
  FR: 'fr',
  IT: 'it',
  EN: 'en',
  PT: 'pt',
  ES: 'es',
  SR: 'sr',
  HR: 'hr',
  BS: 'bs',
  AL: 'al',
  UA: 'ua',
  RU: 'ru',
}

const LANGUAGES_NAME = {
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  en: 'English',
  es: 'Español',
  hr: 'Hrvatski',
  pt: 'Português',
  sq: 'Shqip',
  sr: 'Srpski',
  al: 'Shqiptare',
  ru: 'Русский',
  ua: 'Український',
  bs: 'Bosanski',
}

const ALL_LANGUAGES = Object.values(LANGUAGES)

const LANDING_PAGE_LANGUAGES = [LANGUAGES.DE, LANGUAGES.FR, LANGUAGES.IT, LANGUAGES.EN]

const LANGUAGE_BY_BRAND = {
  [BRANDS.onelog]: ALL_LANGUAGES,
  [BRANDS.twentymin]: ALL_LANGUAGES,
  [BRANDS.srg]: [LANGUAGES.DE, LANGUAGES.FR, LANGUAGES.IT, LANGUAGES.EN],
  [BRANDS.moonandstars]: [LANGUAGES.DE, LANGUAGES.IT],
  [BRANDS.lessentiel]: [LANGUAGES.DE, LANGUAGES.FR],
  [BRANDS.redsport]: [LANGUAGES.DE, LANGUAGES.FR, LANGUAGES.IT, LANGUAGES.EN],
  [BRANDS.jobs]: [LANGUAGES.DE, LANGUAGES.FR, LANGUAGES.EN],
  [BRANDS.jobup]: [LANGUAGES.FR, LANGUAGES.EN],
  [BRANDS.jobscout24]: [LANGUAGES.DE, LANGUAGES.FR, LANGUAGES.EN],
  [BRANDS.programmzeitschriften]: [LANGUAGES.DE, LANGUAGES.FR],
  [BRANDS.zff]: [LANGUAGES.DE, LANGUAGES.FR, LANGUAGES.EN],
  [BRANDS.autodesjahres]: [LANGUAGES.DE, LANGUAGES.FR, LANGUAGES.IT],
  [BRANDS.watson]: [LANGUAGES.DE, LANGUAGES.FR],
  default: [],
}

const URLS_TO_REDIRECT = {
  Root: '',
  About: '/about',
  Impressum: '/impressum',
  PrivacyPolicy: '/privacy-policy',
  AGB: '/agb',
  Support: '/support',
}

const LANDING_PAGE_REDIRECTIONS = {
  [URLS_TO_REDIRECT.Root]: {
    lang: LANDING_PAGE_LANGUAGES,
    to: '/',
  },
  [URLS_TO_REDIRECT.About]: {
    lang: LANDING_PAGE_LANGUAGES,
    to: '/',
  },
  [URLS_TO_REDIRECT.Impressum]: {
    lang: ALL_LANGUAGES,
    to: '/impressum',
  },
  [URLS_TO_REDIRECT.PrivacyPolicy]: {
    lang: ALL_LANGUAGES,
    to: '/privacy-policy',
  },
  [URLS_TO_REDIRECT.AGB]: {
    lang: ALL_LANGUAGES,
    to: '/agb',
  },
  '/support': {
    lang: LANDING_PAGE_LANGUAGES,
    to: '/support',
  },
}

const MAX_YEARS = 100
const MIN_YEARS = 14
const LEGACY_PASSWORD_MAX_LENGTH = 128
const PASSWORD_MAX_LENGTH = 32
const EMAIL_MAX_LENGTH = 80
const STRING_MAX_LENGTH = 75
const NAME_MAX_LENGTH = 30
const LONG_TEXT_MAX_LENGTH = 500
const GAME_LONG_TEXT_MAX_LENGTH = 1000
const GAME_TEXT_MAX_LENGTH = 100

const VALID_AUTH_TIME = 60 * 4

export {
  PAGE_TYPES,
  BRANDS,
  BRAND_PARTNERS,
  FEDERATED_PROVIDERS,
  PROFILE_TABS,
  ID_LIST,
  ELEMENTS_IDS,
  INTERACTION_UID,
  GP_NUMBER_SUPPORT,
  LOGIN_CASES,
  NATIVE_IDENTITY_PREFIX,
  PROFILE_SUPPORT_BY_BRAND,
  SKIP_WEB_AUTHN_SETUP,
  WEB_AUTHN_ACCOUNTS_COOKIE_NAME,
  WEB_AUTHN_CREDENTIALS_LOCAL_STORAGE_KEY,
  BIOMETRIC_TYPES,
  LOGIN_HINT_CHECK_ERROR,
  EMAIL_FORCE_CHECK_ERROR,
  POST_REDIRECT_ACTION,
  POST_REDIRECT_MODAL_TYPES,
  TOTP_SETUP_VIEWS,
  BRAND_IDS,
  EMAIL_CHANGE_VERIFICATION_STATUS,
  LANGUAGES,
  ALL_LANGUAGES,
  LANGUAGE_BY_BRAND,
  LANGUAGES_NAME,
  LANDING_PAGE_LANGUAGES,
  LANDING_PAGE_REDIRECTIONS,
  URLS_TO_REDIRECT,
  MOBILE_NUMBER_INTEGRITY_CHECK_FIELD_NAME,
  MAX_YEARS,
  MIN_YEARS,
  LEGACY_PASSWORD_MAX_LENGTH,
  PASSWORD_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  STRING_MAX_LENGTH,
  NAME_MAX_LENGTH,
  LONG_TEXT_MAX_LENGTH,
  GAME_LONG_TEXT_MAX_LENGTH,
  GAME_TEXT_MAX_LENGTH,
  VALID_AUTH_TIME,
  TAMEDIA_BRANDS,
}
