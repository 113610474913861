import {findRouteToRedirect, getLandingPageRoute} from 'utils/redirections'

const startEntryPoint = async () => {
  import('core-js/features/array/keys')
  import('core-js/features/array/from')
  import('core-js/features/object')
  import('core-js/features/array/includes')
  import('core-js/features/array/find')
  import('core-js/features/array/find-index')
  import('core-js/features/string/pad-start')
  import('core-js/features/string/includes')
  import('core-js/features/string/starts-with')
  import('react-app-polyfill/ie11')
  import('url-search-params-polyfill')
  import('fast-text-encoding')
  const React = (await import('react')).default
  const ReactDOM = (await import('react-dom')).default
  const Root = (await import('Root')).default

  ReactDOM.render(<Root />, document.getElementById('root'))
}

const route = findRouteToRedirect(window.location.href)
if (route !== undefined) {
  const landingPageURL = getLandingPageRoute(route)
  window.location.replace(landingPageURL)
} else {
  startEntryPoint()
}
